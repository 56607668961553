<template>
  <footer class="footer_area">
    <div class="footer_contact text-center">
      <div class="container">
        <div class="col-md-4 col-sm-4">
          <div class="footer_contact_width text-left">
            <p><i class="icon-placeholder"></i> Address <span>1612 Runymeade Ave, Decatur AL</span></p>
          </div><!--end .footer_contact_width-->
        </div><!--end .col-md-4-->
        <div class="col-md-4 col-sm-4">
          <div class="footer_contact_width">
            <p><i class="icon-contact"></i> Email <span><a href="mailto:bobby.vest@vestplumbing.com">bobby.vest@vestplumbing.com</a></span></p>
          </div><!--end .footer_contact_width-->
        </div><!--end .col-md-4-->
        <div class="col-md-4 col-sm-4">
          <div class="footer_contact_width text-right">
            <p><i class="icon-clock"></i> Open Hours <span><a href="">Mon-Fri: 7am - 3pm</a></span></p>
          </div><!--end .footer_contact_width-->
        </div><!--end .col-md-4-->
      </div><!--end .container-->
    </div><!--end .footer_contact-->
    <div class="footer_content section_padding">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-6">
            <div class="footer_textwidget textwidget">
              <h2>About Vest Plumbing</h2>
              <p>
                Vest Plumbing is a fully licensed and insured plumbing company located in Decatur AL, servicing all your gas, water, and sewer needs. Our phones are staffed 24 hours a day, so you can always talk to a live person. 
              </p>
              <h4>Get a Quote</h4>
              <span class="number">256 214 1556</span>
            </div><!--end .footer_textwidget .textwidget-->
          </div><!--end .col-md-4-->
          <div class="col-md-2 col-sm-3">
            <h2>Quick Links</h2>
            <ul class="footer_link">
              <li><a href="/service">Services</a></li>
              <li><a href="/contact">Request Appointment</a></li>
              <li><a href="/#">Home</a></li>
              <li><a href="/about">About Us</a></li>
            </ul><!--end .footer_link-->
          </div><!--end .col-md-2-->
          <div class="col-md-2 col-sm-3">
          </div><!--end .col-md-2-->
          <div class="col-md-4 col-sm-6">
            <div class="newslatter">
              <h2>Newsletter</h2>
              <input type="EMAIL" placeholder="Enter your email">
              <button class="btn-yellow" value="SUBMIT NOW">SUBSCRIBE</button>
            </div><!--end .newslatter-->
          </div><!--end .col-md-4-->
        </div><!--end .row-->
        <div class="copyright_area">
          <div class="row">
            <div class="col-md-8 col-sm-6 copyright_text">
              <p>&copy; copyright 2022 by AgileDesktops.com</p>
            </div><!--end .col-md-8-->
            <div class="col-md-4 col-sm-6 copyright_social text-right">
              <ul>
                <!--<li>
                  <a href="#">
                    <i class="fa fa-twitter"></i>
                  </a>
                </li>
                <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                <li><a href="#"><i class="fa fa-youtube-play"></i></a></li>-->
              </ul>
            </div><!--end .col-md-4-->
          </div><!--end .row-->
        </div><!--end .copyright_area-->
      </div><!--end .container-->
    </div><!--end .footer_content-->
    <img src="/assets/images/shape/footer-shape.png" class="footer_shape" alt="footer shape">
  </footer>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>

</style>
