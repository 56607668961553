import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _eec68414 = () => interopDefault(import('..\\pages\\about.vue' /* webpackChunkName: "pages_about" */))
const _620f866e = () => interopDefault(import('..\\pages\\blog.vue' /* webpackChunkName: "pages_blog" */))
const _f858e284 = () => interopDefault(import('..\\pages\\blog-details.vue' /* webpackChunkName: "pages_blog-details" */))
const _523c266e = () => interopDefault(import('..\\pages\\contact.vue' /* webpackChunkName: "pages_contact" */))
const _400371c0 = () => interopDefault(import('..\\pages\\index-2.vue' /* webpackChunkName: "pages_index-2" */))
const _61813cde = () => interopDefault(import('..\\pages\\service.vue' /* webpackChunkName: "pages_service" */))
const _b20cac5a = () => interopDefault(import('..\\pages\\service-details.vue' /* webpackChunkName: "pages_service-details" */))
const _af56ce8a = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _eec68414,
    name: "about"
  }, {
    path: "/blog",
    component: _620f866e,
    name: "blog"
  }, {
    path: "/blog-details",
    component: _f858e284,
    name: "blog-details"
  }, {
    path: "/contact",
    component: _523c266e,
    name: "contact"
  }, {
    path: "/index-2",
    component: _400371c0,
    name: "index-2"
  }, {
    path: "/service",
    component: _61813cde,
    name: "service"
  }, {
    path: "/service-details",
    component: _b20cac5a,
    name: "service-details"
  }, {
    path: "/",
    component: _af56ce8a,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
